<template>
    <div>
        <city-list v-if="!isBlock" @selectedCity="selectedCid"></city-list>
        <div class="maincon">
            <div class="blockbox">
                <el-switch v-model="showPic" inactive-text="覆盖物">
                </el-switch>
            </div>

            <div class="bmap-page-container">
                <div class="plugin">
                    <div :class="{'pbtn':true, 'activebtn':'vector'=== currentMapLayer.type}" @click="toggleMapType('vector')">矢量地图</div>
                    <div :class="{'pbtn':true, 'activebtn':'satellite'=== currentMapLayer.type}" @click="toggleMapType('satellite')">影像地图</div>
                </div>

                 <l-map style="width: 100%; height: 700px;z-index:1"  :zoom="zoom" :center="center">
                      <l-tile-layer :url="currentMapLayer.url" ></l-tile-layer>
                      <l-tile-layer :url="currentMapLayer.texturl" :attribution="currentMapLayer.attribution"></l-tile-layer>

                    <l-image-overlay :opacity="groundimage.visible" :url="groundimage.url" :bounds="groundimage.bounds" :key="index" v-for="(groundimage, index) in groundimages"></l-image-overlay>
                 </l-map>
                <!-- <el-bmap vid="bmapindex" :bmap-manager="bmapManager" :zoom="zoom" :center="center" :events="events"
                    class="bmap-demo">
                    <weixing-plugin :map="map"></weixing-plugin>

                    <el-bmap-ground-overlay ref="overlay" v-for="(groundimage, index) in groundimages"
                        :key="groundimage.file_id" :url="groundimage.url" :visible="groundimage.visible"
                        :bounds="groundimage.bounds" :opacity="groundimage.opacity" :events="groundimage.events">
                    </el-bmap-ground-overlay>


                </el-bmap> -->

            </div>

        </div>
    </div>
</template>

<script>

// import weixingPlugin from '@/components/bmap/weixingPlugin.vue'
// import VueBMap from "vue-bmap-gl";
// import { lazyBMapApiLoaderInstance } from 'vue-bmap-gl';

import CityList from '@/components/cityreserch/CityList.vue'
import { mapPic, blockDetail } from '@/api/cityresearch'
import { LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LPolyline,
  LTooltip,
  LPolygon,
  LImageOverlay,
  LIcon } from 'vue2-leaflet';

import { vectorMapLayer,satelliteMapLayer,leafletmapurl,leafletmaptexturl,leafletmapyunxuanurl, isgaode } from "@/settings.js";

// let bmapManager = new VueBMap.BMapManager();

import { latLng, icon } from "leaflet";


export default {
    components: {
        CityList,
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        LPolyline,
        LTooltip,
        LPolygon,
        LIcon,
        LImageOverlay
    },

    props: {
        isBlock: {
            type: Boolean,
            default: false
        },
        cate: {
            type: Number,
            default: 2  //国土1 //总体2 //板块详细规划3
        },//1 2 3
        polygondata: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    computed: {
        shi: {
            get() {
                return this.$store.state.shi
            },
            set(v) {
                this.$store.dispatch('changeShi', v)
            }
        },
        block: {
            get() {
                return this.$store.state.block
            },
            set(v) {
                this.$store.dispatch('changeBlock', v)
            }
        }
    },

    watch: {
        isBlock: {
            handler(v) {
                if (v) {
                    this.selectedCid(this.block)
                }
            }
        },

        showPic: {
            handler(v) {
                // const changeOpacity = parseFloat(v / 100)
                //获取覆盖物
                // this.$refs.overlay.map(item => {
                //     console.log(item.$$getInstance().setOpacity(0.1))
                // })

                const current = JSON.parse(JSON.stringify(this.groundimages))
                const groundimagesall = current.map(item => {
                    item.visible = item.visible === 1 ? 0 : 1
                    // item.opacity = changeOpacity

                    return item
                })

                console.log('groundimagesall', groundimagesall)

                this.groundimages = groundimagesall

                console.log(' this.groundimages ', this.groundimages)
            }


        },

        // shi: {
        //     handler(v) {
        //         this.getCenterPoint()
        //         this.getPICData()

        //     },
        //     deep: true
        // },

        block: {
            handler(v) {
                if (this.isBlock) {
                    this.selectedCid(v)
                }
            },
            immediate: true
        },

        polygondata: {
            handler(v) {
                this.getCenterPoint()


            },
            deep: true
        }
    },

    mounted() {

    },
    data() {
        return {


            
            //矢量地图对象
            vectorMapLayer:vectorMapLayer ,
            //卫星地图数据对象
            satelliteMapLayer: satelliteMapLayer,
            //当前地图图层
            currentMapLayer:{url:'',texturl:'',attribution:'',type:'vector'},


              center: L.latLng(),
            zoom: 10,
            crs: L.CRS.EPSG3857, // 使用的是EPSG:3857坐标系
            url: leafletmapurl,
            
             attribution: '',
            opacityall: 1,
            showPic: true,
            area: '',
            pointsList: [],
            visible: 1,
            polygons: [],
            geodata: "",
            count: 1,
          
            groundimages: [

            ]
        };
    },
     created() {
     this.currentMapLayer = this.vectorMapLayer;
     this.currentMapLayer.type = 'vector'
  },


  methods: {
     toggleMapType(type) {
      if (type === 'vector') {
        this.currentMapLayer = this.vectorMapLayer;
        this.currentMapLayer.type = 'vector'
      } else if (type === 'satellite') {
        this.currentMapLayer = this.satelliteMapLayer;
        this.currentMapLayer.type = 'satellite'
      }
    },
        icon(num) {
            if(num == null || num == ''){

            return icon({
                iconUrl: require("@/assets/opcity.png"),
                iconSize: [32, 37],
                iconAnchor: [16, 37]
            });
            }else{
            return icon({
                iconUrl: require("@/assets/type" + num + ".png"),
                iconSize: [32, 37],
                iconAnchor: [16, 37],
            });
            }
        
        },


        selectedCid(cid) {
            this.getCenterPoint(cid)
            this.getPICData(cid)

        },

        //获取地图的中心点
        getCenterPoint(cityplateid = 0) {
            let params;

            if (cityplateid) {
                params = { city_plate_id: cityplateid ,is_gaode:isgaode}
            } else {

                if (this.cate === 3) {
                    params = { city_plate_id: this.block }
                } else {
                    params = { city_plate_id: this.shi }
                }
            }


            blockDetail(params).then(res => {
                this.center = [res.data.lat_gaode,res.data.lng_gaode]
                this.zoom = 10;
            })

        },


        getPICData(cityplateid = 0) {

            let params;
            if (cityplateid) {
                params = { city_plate_id: cityplateid, type: this.cate ,is_gaode:isgaode}
            } else {
                if (this.cate === 3) {
                    params = { city_plate_id: this.block, type: this.cate }
                } else {
                    params = { city_plate_id: this.shi, type: this.cate }
                }
            }



            mapPic(params).then(res => {
                // console.log('res', res)
                // this.center = [res.data.lng, res.data.lat]
                // this.zoom = 15;

                this.handleResImges(res.data)


            })
        },

        handleResImges(data) {
            const groundimages = data.map(item => {
                const obj = {
                    file_id: item.file_id,
                    url: item.file_url,
                    visible: 1,
                    opacity: 1,
                    // bounds: [[item.sw.lng, item.ne.lat], [item.ne.lng, item.sw.lat]],
                    
                    bounds: [[ item.ne_gaode.lat,item.sw_gaode.lng], [item.sw_gaode.lat,item.ne_gaode.lng]],
                    events: {
                        click(e) {
                            console.log('click overlay', e);
                            alert('click groundimage');
                        }
                    }
                }
                return obj
            })

            this.groundimages = groundimages


        }



    }


};
</script> 
<style lang="scss" scoped>
.plugin{
  height:0px;
  width:120px;
  display: flex;
  background: #fff;
  position: relative;
  float: left;
  top:20px;
  z-index: 1000;
  left:60px;
  .pbtn{
    width:60px;
    height:20px;
    color:#000;
    font-weight: bold;
    line-height: 20px;
    background: #fff;
    border: solid 1px #FFF;
    cursor: pointer;
  }
  .activebtn{
    color:#1384cf;
    border: solid 1px #1384cf;
  }
}


/* 隐藏国企以及版权标识 */
::v-deep .leaflet-control-attribution,
.leaflet-control {
  display: none !important;
}


.bmap-page-container {
    width: 98%;
    margin-left: 1%;
}

.maincon {
    position: relative;
    height: calc(100vh - 180px);
}

.bmap-demo {
    height: calc(100vh - 180px);
}

.labelbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 5px;

    .labelcontent {
        border: solid 1px #fff;
        background: #fff;
        border-radius: 4px;
        color: #333;
        font-size: 14px;
        padding: 4px;
        display: flex;
        flex-direction: row;
        padding: 5px 10px;
        justify-content: space-between;
        align-items: center;

        .iconimg {
            height: 30px;
            width: 30px;
        }

        .labeltext {
            margin-left: 10px;
        }

        // mar
    }

    .labeldown {
        width: 0;
        height: 0;
        border-top: 10px solid #fff;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;

    }
}

.blockbox {
    height: 50px;
    width: 140px;
    position: absolute;
    top: 5%;
    right: 40px;
    z-index: 20;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}
</style>